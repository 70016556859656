<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div class="row mt-2">
                            <div class="col-lg-2">
                                <v-autocomplete v-model="regionModel" :items="regionLists" default="" item-value="region" item-text="region" :label=labelRegion clearable dense :disabled="disabled_region == true"  @change="(event) => regionOnChange(event)" prepend-icon="mdi-city"></v-autocomplete>
                            </div>
                            <div class="col-lg-3">
                                <v-autocomplete v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" :label=labelBranch clearable dense :disabled="disabled_office == true"  @change="(event) => officeOnChange(event)" prepend-icon="mdi-home-variant"></v-autocomplete>
                            </div>
                            <div class="col-lg-2">
                                <v-autocomplete v-model="salesModel" :items="salesLists" default="" item-value="SalesId" item-text="NamaSales" :label=labelSales clearable dense :disabled="disabled_salesman == true" prepend-icon="mdi-account"></v-autocomplete>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Period" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Period" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" :show-current="true" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-1">
                                <v-btn class="mt-1" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="card elevation-5">
                    <div class="card-body">
                        <h5 class="card-title">Active Outlet Result</h5>
                        <div class="table-responsive">
                            <table class="styled-table">
                                <thead>
                                    <tr>
                                        <th>Period</th>
                                        <th v-for="item in itemList" :key="item.period">
                                            {{ item.period }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>New Customer</td>
                                        <td v-for="item in itemList" :key="item.period">
                                            {{ item.new_customer }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Active Outlet</td>
                                        <td v-for="item in itemList" :key="item.period">
                                            {{ item.active_customer }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>NOO Last 12 months</td>
                                        <td v-for="item in itemList" :key="item.period">
                                            {{ item.newOutletOrderLast12months }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Ratio</td>
                                        <td v-for="item in itemList" :key="item.period">
                                            {{ parseFloat(item.totalOutletRatio.toFixed(2)) }} %
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

import CryptoJS from 'crypto-js';

export default {
    components: {
        
    },
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Dashboard',
                disabled: false,
                href: '#',
                },
                {
                text: 'Outlet Ratio',
                disabled: true,
                href: '#',
                },
            ],
            // itemList: [
            //     {
            //         period: '202401',
            //         new_customer: '141',
            //         active_outlet: '250',
            //         order_ratio: '15.5%'
            //     },
            //     {
            //         period: '202402',
            //         new_customer: '125',
            //         active_outlet: '222',
            //         order_ratio: '17.3%'
            //     },
            //     {
            //         period: '202403',
            //         new_customer: '187',
            //         active_outlet: '349',
            //         order_ratio: '21.2%'
            //     },
            //     {
            //         period: '202404',
            //         new_customer: '141',
            //         active_outlet: '250',
            //         order_ratio: '15.5%'
            //     },
            //     {
            //         period: '202405',
            //         new_customer: '125',
            //         active_outlet: '222',
            //         order_ratio: '17.3%'
            //     },
            //     {
            //         period: '202406',
            //         new_customer: '187',
            //         active_outlet: '349',
            //         order_ratio: '21.2%'
            //     },
            //     {
            //         period: '202407',
            //         new_customer: '141',
            //         active_outlet: '250',
            //         order_ratio: '15.5%'
            //     },
            //     {
            //         period: '202408',
            //         new_customer: '125',
            //         active_outlet: '222',
            //         order_ratio: '17.3%'
            //     },
            //     {
            //         period: '202409',
            //         new_customer: '187',
            //         active_outlet: '349',
            //         order_ratio: '21.2%'
            //     },
            //     {
            //         period: '202410',
            //         new_customer: '141',
            //         active_outlet: '250',
            //         order_ratio: '15.5%'
            //     },
            //     {
            //         period: '202411',
            //         new_customer: '125',
            //         active_outlet: '222',
            //         order_ratio: '17.3%'
            //     },
            //     {
            //         period: '202412',
            //         new_customer: '187',
            //         active_outlet: '349',
            //         order_ratio: '21.2%'
            //     },
            // ],
            itemList: [],
            labelRegion: 'Region',
            regionModel: '',
            regionLists: [],
            labelBranch: 'Branch Office',
            officeModel: '',
            officeLists: [],
            labelSales: 'Salesman',
            salesModel: '',
            salesLists: [],
            disabled_region: false,
            disabled_office: false,
            disabled_salesman: false,
            dateFromFormatted: '',
            modalDateFrom: false,
            dateFrom: '',
            dateToFormatted: '',
            modalDateTo: false,
            dateTo: '',
        }
    },
    created() {
        
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){

            this.$store.dispatch('setOverlay', true)
            
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OutletRatio`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }
                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }
                if(res.data.sales.length == 1){
                    this.salesModel = res.data.sales[0].SalesId
                    this.salesLists = res.data.sales
                    this.disabled_salesman = true
                } else {
                    this.salesLists = res.data.sales
                    this.disabled_salesman = false
                }

                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async getData(){

            this.$store.dispatch('setOverlay', true)

            if (this.dateFrom) {
                this.dateFromFormatted = new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,"")
            } else {
                this.dateFromFormatted = ''
            }

            if (this.dateTo) {
                this.dateToFormatted = new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
            } else {
                this.dateToFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OutletRatio/getData`, { 
                region: this.regionModel ? this.regionModel : '',
                office: this.officeModel ? this.officeModel : '',
                sales: this.salesModel ? this.salesModel : '',
                startPeriod: this.dateFromFormatted ? this.dateFromFormatted : '',
                endPeriod: this.dateToFormatted ? this.dateToFormatted : '',
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                this.itemList = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err);
                
                // if(err.response.status == '401'){
                //     this.$router.push('/');
                // }

            })

        },

        async regionOnChange(id){

            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReport/regionOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.officeLists = res.data.office
                this.labelBranch = 'Branch Office(' + res.data.countOffice + ')'
                this.salesLists = res.data.sales
                this.labelSales = 'Salesman(' + res.data.countSales + ')'

                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async officeOnChange(id){

            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReport/officeOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${this.regionModel ? this.regionModel : ''}&office=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.salesLists = res.data.sales
                this.labelSales = 'Salesman(' + res.data.countSales + ')'

                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

    },

    watch: {

        
    }
    
}
</script>

<style scoped>

    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .styled-table {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;
        font-size: 1.2em;
        font-family: Arial, sans-serif;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
        border: 1px solid #ddd;
    }

    .styled-table th,
    .styled-table td {
        padding: 12px 15px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    .styled-table th {
        background-color: #f2f2f2;
        color: #333;
        font-weight: bold;
    }

    .styled-table tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    .styled-table tr:hover {
        background-color: #f1f1f1;
    }

    .styled-table td {
        color: #555;
    }

</style>